<template>
	<div class="dashboard-bg" style="overflow: hidden;" :class="[!this.$store.getters.dashboardAccess && !this.$cookies.isKey('lnd_gst_dash') ? 'restrict-overlay' : '']">
		<InactivityNotice v-if="this.$store.getters.user && !this.$store.getters.user.details.is_guest"/>
		<Orientation />
		<GuestModal/>
		<div class="bg-holder-ap"></div>
		<section class="dashboard-wrap" :class="!this.$store.getters.dashboardAccess && !this.$cookies.isKey('lnd_gst_dash') ? 'disabled' : ''">
			<router-view></router-view>
		</section>
	</div>
</template>
 

<script>
import { Modal } from "bootstrap";
import Orientation from "../components/OrientationCheck.vue"
import InactivityNotice from "../components/InactivityNotice.vue"
import GuestModal from "../components/LandingGuestModal.vue"
export default {
	name: 'AppView',
	components: {
		Orientation,
		InactivityNotice,
		GuestModal
	},

	data() {
		return {
			guest_mdl: null,
		}
	},

	methods: {
		chatraBox: function (d, w, c) {
			w.ChatraID = "KcH4TKXCzr9G9wjzR";
			var s = d.createElement("script");
			w[c] = w[c] || function () {
				(w[c].q = w[c].q || []).push(arguments);
			};
			s.async = true;
			s.src = "https://call.chatra.io/chatra.js";
			if (d.head) d.head.appendChild(s);

		},

		isInIframe: function () {
			return window.self !== window.top;
		},
	},

	updated () {},
	

	created(){
		this.chatraBox(document, window, "Chatra")
		window.ChatraSetup = {
			startHidden: true
		};
	},

	mounted() {
		let rt_tst = this.$route.name === 'profile-view'
		this.guest_mdl = new Modal('#gst_lnd_mdl');
		if(this.$cookies.isKey('lnd_gst') && this.$cookies.isKey('spn_cnt') && !rt_tst) {
			this.guest_mdl.show()
		}

		if(this.$route.meta.restrictInIframe && this.isInIframe()) {
			this.$router.push('/widget/sales')
		} 

		if(this.$route.meta.allowInIframe && !this.isInIframe()) {
			this.$router.push('/')
		} 
	},

	watch: {
		$route() {
			if(this.$route.name === 'profile-view') {
				this.guest_mdl.hide()
			} else {
				if(this.$cookies.isKey('lnd_gst') && this.$cookies.isKey('spn_cnt')) {
					this.guest_mdl.show()
				}
			}
		}
	}
	
}

</script>