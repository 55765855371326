import { createStore } from "vuex";
import UserService from '../api-services/user.service'
import axios from 'axios'
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')
import VueCookies from 'vue-cookies';

const store = createStore({
  globalInterval: null,
  state : {
    loading: false,
    user: null,
    user_id_ifr: null,
    login_at: null,
    token: (localStorage.getItem('user-token')) ? localStorage.getItem('user-token') : null,
    user_email:  (localStorage.getItem('user_email')) ? localStorage.getItem('user_email') : null,
    user_balance: (localStorage.getItem('b')) ? localStorage.getItem('b') : null,
    status: null,
    dashboard_access: null,
    bonus_collected: null,
    first_game: null,
    claimable_rewards_count: null,
    rewards_timer_data_elapsed: null,
    user_referral: null,
    sound_state: null
  },

  getters: {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    userId: state => state.user.details.id,
    userIdIfr: state => state.user_id_ifr,
    userLoginTimestamp: state => state.login_at,
    authStatus: state => state.status,
    getUserEmail: state => state.user.details.email,
    userSound: state => state.sound_state,
    userBalance: state => state.user_balance,
    userReferral: state => state.user_referral,
    dashboardAccess: state => state.dashboard_access,
    bonusCollected: state => state.bonus_collected,
    firstGame: state => state.first_game,
    rewardTimerData: state => state.rewards_timer_data_elapsed,
    claimableCount: state => state.claimable_rewards_count,
    isLoading: state => state.loading
  },

  mutations: {

    LOADING_CHANGE (state, payload) {
      state.loading = payload

      // console.log(state.loading)
    },

    AUTH_SUCCESS (state, token) {
      state.status = 'success'
      state.token = token
      
      this.globalInterval = setInterval(() => {
        // console.log('store interval', this.globalInterval)
        UserService.pingClaimableReward()
          .then((data) => {
            store.dispatch('setTimeRewards', data.data.time_elapsed)
            store.dispatch('setClaimableItemsCount', data.data.claimable_items)
          })
      }, 1000 * 60)

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST_URL,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        authEndpoint: `${process.env.VUE_APP_API_ENDPOINT}/broadcasting/auth`,
        encrypted: false,
        forceTLS: false,
        //  wssPort: 6001,
        disableStats: true,
        enabledTransports: ['ws'/*, 'wss'*/],
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        auth: {
            headers: {
            authorization: 'Bearer ' + token,
            }
        }
      })

    },

    AUTH_SET_USER (state, user) {
      state.user = user
      state.login_at = user.details.login_at
      state.dashboard_access = user.dashboard
      state.bonus_collected = user.daily_bonus.collected
      state.claimable_rewards_count = user.active_time_reward.claimable_items
    },

    AUTH_SET_USER_ID_IFR (state, id) {
      state.user_id_ifr = id
    },

    AUTH_SET_REWARDS_TIMER (state, data) {
      state.rewards_timer_data_elapsed = data
    },

    AUTH_SET_SOUND_STATE (state, data) {
      state.sound_state = data
    },

    AUTH_SET_REFERRAL (state, data) {
      state.user_referral = data
    },

    SET_CLAIMABLE_ITEMS_COUNT(state, data) {
      state.claimable_rewards_count = data
    },

    AUTH_ERROR (state) {
      state.status = 'error'
    },

    AUTH_LOGOUT (state) {
      state.status = null
      state.token = null
      state.user = null
      state.user_id_ifr = null
      state.login_at = null
      state.dashboard_access = null
      state.bonus_collected = null
      state.first_game = null
      state.user_email = null
      state.claimable_rewards_count = null
      state.rewards_timer_data_elapsed = null
      state.sound_state = null
      clearInterval(this.globalInterval)
    },

    SET_EMAIL_ADDRESS (state, email) {
      state.user_email = email;
    },

    SET_USER_BALANCE (state, balance) {
      state.user_balance = balance;
    },

    SET_FIRST_GAME(state, game) {
      state.first_game = game
    },
  },

  actions: {

    hideLoading ({ commit }) {
      commit('LOADING_CHANGE', false)
    },

    showLoading ({ commit }) {
      commit('LOADING_CHANGE', true)
    },

    authRequest ({ commit }, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login

        UserService.login(user)
          .then((response) => {
            const token = response.data.access_token
            localStorage.setItem('user-token', token) // store the token in localstorage
            
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('AUTH_SUCCESS', token)

            resolve(response)
          })
          .catch((error) => {
            // console.log(error, 'authRequest ')
            commit('AUTH_ERROR', error)
            localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
            reject(error)
          })
      })
    },

    registerRequest ({ commit }, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login

        UserService.completeUserRegister(user)
          .then((response) => {
            
            const token = response.data.access_token
            localStorage.setItem('user-token', token) // store the token in localstorage
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('AUTH_SUCCESS', token)

            resolve(response)
          })
          .catch((error) => {
            // console.log(error, 'registerRequest ')
            commit('AUTH_ERROR', error)
            localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
            reject(error)
          })
      })
    },

    loginAsGuest ({ commit }, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        
        UserService.loginAsGuest(user)
          .then((response) => {

            const token = response.data.access_token
            localStorage.setItem('user-token', token) // store the token in localstorage
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            commit('AUTH_SUCCESS', token)

            resolve(response)
          })
          .catch((error) => {
            // console.log(error, 'loginAsGuest ')
            commit('AUTH_ERROR', error)
            localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
            reject(error)
          })
      })
    },

    logoutRequest ({ commit }) {
      document.cookie = 'synced=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      return new Promise((resolve, reject) => {
        commit('AUTH_LOGOUT')
        UserService.logout()
          .then(() => {
            window._cio.reset()
            
            localStorage.removeItem('user-avatar')
            localStorage.removeItem('user_email')
            localStorage.removeItem('user-token')
            localStorage.removeItem('ui_ifr')
            localStorage.removeItem('binded-account')
            delete axios.defaults.headers.common['Authorization']
            VueCookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
            if (VueCookies.isKey('lnd_gst')) {
							VueCookies.remove('lnd_gst')
						}
						if (VueCookies.isKey('spn_cnt')) {
							VueCookies.remove('spn_cnt')
						}
						if(VueCookies.isKey('lnd_gst_dash')){
							VueCookies.remove('lnd_gst_dash')
						}

            window.Echo = null
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })

      })
    },

    logoutGuestToEmail ({ commit }) {
      document.cookie = 'synced=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      return new Promise((resolve, reject) => {
        commit('AUTH_LOGOUT')
        UserService.logout()
          .then(() => {

            window._cio.reset()
            delete axios.defaults.headers.common['Authorization']
            VueCookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
            if (VueCookies.isKey('lnd_gst')) {
							VueCookies.remove('lnd_gst')
						}
						if (VueCookies.isKey('spn_cnt')) {
							VueCookies.remove('spn_cnt')
						}
						if(VueCookies.isKey('lnd_gst_dash')){
							VueCookies.remove('lnd_gst_dash')
						}
            localStorage.removeItem('user-token')
            
            window.Echo = null
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })

      })
    },

    setEmail({ commit } , email) {
      localStorage.setItem('user_email', email)
      commit('SET_EMAIL_ADDRESS', email)
    },

    removeEmailLS() {
       localStorage.removeItem('user_email')
    },

    authSuccess( {commit}, payload ) {
      commit('AUTH_SUCCESS', payload)
    },

    authError( {commit}, error ) {
      commit('AUTH_ERROR', error)
    },

    setUser( {commit}, payload ){
      commit('AUTH_SET_USER', payload)
    },

    setUserIdIfr( {commit}, payload ){
      commit('AUTH_SET_USER_ID_IFR', payload)
    },

    setTimeRewards( {commit}, payload ){
      commit('AUTH_SET_REWARDS_TIMER', payload)
    },

    setSoundState( {commit}, payload ){
      commit('AUTH_SET_SOUND_STATE', payload)
    },

    setReferral( {commit}, payload ){
      commit('AUTH_SET_REFERRAL', payload)
    },

    setClaimableItemsCount( {commit}, payload ){
      commit('SET_CLAIMABLE_ITEMS_COUNT', payload)
    },

    setFirstGame( {commit}, payload ) {
      commit ('SET_FIRST_GAME', payload)
    },

    setUserBalance( {commit}, payload) {
      commit('SET_USER_BALANCE', payload)
    },
  },

})


export default store;
