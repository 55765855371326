<template>
    <div id="sl_app">
        <nav class="navbar-cls">
            <p class="float-right cst-txt" @click="installer()" :style="{ 'display': this.installBtn }"
                v-if="this.installBtn">
                Install App
            </p>
            <p class="float-right sme-txt" id="liveToastBtn" :style="{'display': this.installBtn2nd}">
                Install App
            </p>
        </nav>
        <div class="position-fixed top-0 end-0 p-3">
            <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <strong class="me-auto">Install App</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    {{ this.message }}
                </div>
            </div>
        </div>
        <router-view />
        <Loading v-if="this.$store.getters.isLoading" />
    </div>
</template>
<script>


import Loading from "./components/Loading.vue"
import { Toast } from 'bootstrap'
const canWakeLock = () => 'wakeLock' in navigator;
export default {
    name: 'App',
    components: {
        Loading
    },

    data() {
        return {
            share_icon: require('./assets/images/icons/apple-share.png'),
            menu_icon: require('./assets/images/icons/mozilla-menu.png'),
            installBtn: 'none',
            installBtn2nd: 'none',
            installer: undefined,
            wakelock: undefined,
            browser: undefined,
            message: '',
            os: null,
            userAgent: navigator.userAgent
        }
    },

    computed: {},

    methods: {
        lockWakeState: async function () {
            if (!canWakeLock()) return;
            try {
                this.wakelock = await navigator.wakeLock.request();
                this.wakelock.addEventListener('release', () => {
                    // console.log('Screen Wake State Locked:', !this.wakelock.released);
                });
                // console.log('Screen Wake State Locked:', !this.wakelock.released);
            } catch (e) {
                // console.log('Failed to lock wake state with reason:', e.message);
                console.log('Failed to lock wake');
            }
        },

        releaseWakeState: function () {
            if (this.wakelock) this.wakelock.release()
            this.wakelock = null
        },


        osDetect: function () {
            if (/iphone/i.test(this.userAgent)) {
                this.os = "ios"
            }
            else if (/ipad/i.test(this.userAgent)) {
                this.os = "ios"
            }
            else if (/macintosh/i.test(this.userAgent)) {
                this.os = "macos"
            }
        },

        browserDetect: function () {

            if (this.userAgent.match(/chrome|chromium|crios/i)) {
                this.browser = 'chrome'
                this.message = 'To add the app on the screen press the "Share" icon then choose "Add to home"'
            } else if (this.userAgent.match(/safari/i)) {
                this.browser = 'safari'
                this.message = 'To add the app on the screen press the "Share" icon then choose "Add to home"'
            } else if (this.userAgent.match(/firefox|fxios/i)) {
                this.browser = 'firefox'
                this.message = 'To add the app on the screen press the the menu icon then press "Install"'
            }
        },

        isInIframe: function () {
            return window.self !== window.top;
        },
    },

    mounted() {
    },

    watch: {
		$route() {
			if(this.$route.meta.restrictInIframe && this.isInIframe()) {
				this.$router.push('/widget/sales')
			} 

            if(this.$route.meta.allowInIframe && !this.isInIframe()) {
				this.$router.push('/')
			} 
			// this.$gtag.event('conversion', {
			// 	'event_category': 'send_to',
			// 	// 'event_label': 'label',
			// 	'value': 'AW-11426736329/9h4BCPbFuvsYEMnR2Mgq'
			// })
		}
	},

    async created() {
        this.osDetect()
        this.browserDetect()

        await this.lockWakeState();
        let installPrompt

        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault()
            installPrompt = e
            this.installBtn = 'block'
        })

        this.installer = () => {
            this.installBtn = 'none'
            installPrompt.prompt()
            installPrompt.userChoice.then(result => {
                if (result.outcome === 'accepted') {
                    console.log('App instalation accepted')
                } else {
                    console.log('App instalation denied')
                }
                installPrompt = null
            })
        }

        document.addEventListener("visibilitychange", async () => {
            if (this.wakelock !== null && document.visibilityState === "visible") {
                await this.lockWakeState();
            }
        });
        
        const toastTrigger = document.getElementById('liveToastBtn')
        const toastLiveExample = document.getElementById('liveToast')
        if (toastTrigger) {
            toastTrigger.addEventListener('click', () => {
                const toast = new Toast(toastLiveExample)
                toast.show()
            })
        }

        if (this.os === 'ios' && (this.browser === 'firefox' || this.browser === 'chrome' || this.browser === 'safari')) {  
            this.installBtn2nd = 'block'
            this.installBtn = 'none'
        }

        if(this.browser === 'firefox') {
            this.installBtn2nd = 'block'
            this.installBtn = 'none'
        }

        if((window.matchMedia('(display-mode: fullscreen)').matches) || (window.navigator.fullscreen)) {
            this.installBtn2nd = 'none'
        }

    }
}
</script>

<style scoped lang="scss">

</style>
