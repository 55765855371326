<template>
	<div class="widget-sale-container">
		<router-view></router-view>
        <!-- <p @click="returnToPrevious" class="sfn-btn d-none d-lg-inline-block"><span> Return to Sales </span></p>
		<p class="sfn-btn d-none d-lg-inline-block" @click="logout"> <span> Exit </span> </p> -->
	</div>
</template>

<script>
// import InactivityNotice from "../components/InactivityNotice.vue"
export default {
	name: 'widget-view',
	components: {
		// InactivityNotice
	},

	methods: {

		// returnToPrevious: function () {
        //     window.history.back();
        // },

		isInIframe: function () {
			return window.self !== window.top;
		},

		// logout: function () {
		// 	window.parent.postMessage('closeIframe', '*')
		// 	this.$isLoading(true)
		// 	this.$store.dispatch('logoutRequest').then(() => {
		// 		window.location.href = '/'
		// 	}).catch((error) => {
		// 		this.dummyErrorHolder = error
		// 		this.$isLoading(false)
		// 	})
		// },
	},

	watch: {
		$route() {
			if (this.$route.meta.restrictInIframe && this.isInIframe()) {
				this.$router.push('/widget/sales')
			}

			if (this.$route.meta.allowInFrame && !this.isInIframe()) {
				this.$router.push('/')
			}
		}
	},

	updated() { },

	created() { },

	mounted() {
		if (this.$route.meta.restrictInIframe && this.isInIframe()) {
			this.$router.push('/widget/sales')
		}

		if (this.$route.meta.allowInFrame && !this.isInIframe()) {
			this.$router.push('/')
		}
	}
}

</script>