<template>
	<div class="inner-bg">
		<div class="bg-holder-pb"></div>
		<Loading v-if="this.$store.getters.isLoading" />
		<div id="js-particles"></div>

		<div class="site-mobile-menu site-navbar-target">
			<div class="site-mobile-menu-header">
				<div class="site-mobile-menu-close mt-3">
					<span class="fa-solid fa-xmark js-menu-toggle"></span>
				</div>
			</div>
			<div class="site-mobile-menu-body"></div>
		</div>

		<!-- <header class="site-navbar mt-3"> -->
		<header class="site-navbar pt-3">
			<div class="container">
				<div class="row align-items-center">
					<div class="site-logo col-auto">
						<router-link to="/">
							<img src="../assets/images/logo/slotofun.png" alt="">
						</router-link>
					</div>
					<nav class="mx-auto site-navigation col">
						<ul class="site-menu js-clone-nav d-none d-xl-flex ml-0 pl-0 justify-content-center">
							<li><router-link to="/login" class="nav-link active">Play</router-link></li>
							<li><router-link :to="{ name: 'homepage-view', hash: '#rewards' }">Rewards</router-link></li>
							<li>
								<router-link :to="{ name: 'homepage-view', hash: '#free-coins' }"> Free Coins</router-link>
							</li>
							<!-- <li class="has-children">
								<a href="javascript:void(0)">Free Games</a>
								<ul class="dropdown">
									<li><a href="javascript:void(0)">Top Games</a></li>
									<li><a href="javascript:void(0)">Free Slots Games</a></li>
								</ul>
							</li> -->
							<!-- <li><a href="javascript:void(0)">News</a></li> -->
							<!-- <li><a href="javascript:void(0)">Shop</a></li> -->
							<li class="d-lg-none mt-3 pt-3 border-top"><span class="d-block"><router-link to="/login"
										class="sfn-btn sfn-btn-pink lg d-block"><span>Login</span></router-link></span></li>
							<li class="d-lg-none mt-2"><span class="d-block"><router-link to="/register"
										class="sfn-btn lg d-block"><span>Register</span></router-link></span></li>
						</ul>
					</nav>

					<div class="right-cta-menu text-right d-flex aligin-items-center col-auto">
						<div class="ml-auto d-inline-flex align-items-center">
							<router-link to="/login" class="login-head d-none d-lg-inline-block"> Log In</router-link>
							<router-link to="/register"
								class="sfn-btn d-none d-lg-inline-block"><span>Register</span></router-link>
						</div>
						<a href="#" class="site-menu-toggle js-menu-toggle d-inline-block d-xl-none mt-lg-2 ml-3"><span
								class="fa-solid fa-bars m-0 p-0 mt-2"></span></a>
					</div>
				</div>
			</div>
		</header>
		<router-view></router-view>
		<footer class="page-footer">
			<div class="container">
				<ul class="footer-menu">
					<li><a href="https://www.facebook.com/slotofungames/" target="_blank">Fan Page </a></li>
					<li><router-link to="/cookie-settings">Cookie Settings </router-link> </li>
					<li><router-link to="/terms-service">Terms of Service </router-link> </li>
					<li><router-link to="/privacy-notice">Privacy Notice </router-link> </li>
					<li><a href="javascript:void(0)">Payment</a> </li>
<!--					<li><a href="javascript:void(0)">Support</a> </li>-->
					<li><router-link to="/faq">FAQ </router-link> </li>
				</ul>
				<div class="footer-disclaimer">
					<p>This is a free-to-play, social casino site and is intended for entertainment purposes only. This is not a practice site for real money gaming and playing on SlotoFun does not imply future success playing in real money casinos. We do not offer real money gambling and there are no opportunities to win real money or prizes on SlotoFun.social.</p>
					<p>SlotoFun games and products were created for and offered only to persons 21 years of age or older.</p>
				</div>
				<div class="footer-botbar">
					<div class="copyc">© {{ this.getCurrentYear }} slotofun.social All Rights Reserved</div>
					<div class="footer-socials">
						<a class="btn text-white m-1" style="background-color: #3b5998;" href="#" role="button"><i
								class="fab fa-facebook-f"></i></a>
						<a class="btn text-white m-1" style="background-color: #55acee;" href="#" role="button"><i
								class="fab fa-twitter"></i></a>
						<a class="btn text-white m-1" style="background-color: #ac2bac;" href="#" role="button"><i
								class="fab fa-instagram"></i></a>
						<a class="btn text-white m-1" style="background-color: #FF0000;" href="#" role="button"><i
								class="fab fa-youtube"></i></a>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>
  
<script>
import Loading from "../components/Loading.vue"
export default {
	name: 'PublicView',
	components: {
		Loading
	},

	computed: {
		getCurrentYear: function () {
			let date = new Date()
			let year = date.getFullYear()

			return year
		}
	},
	created() {
		this.setRefCookie()
	},

	methods: {
		setRefCookie: function () {
			if(this.$route.query.ref) {
				this.$cookies.set('ref', this.$route.query.ref, '90d')
			}
		},

		initParticles: function() {
			window.particlesJS(
				'js-particles', {
				'particles': {
					'number': {
						'value': 100
					},
					'color': {
						'value': ['#0db6f1', '#070759', '#ed9ccc']
					},
					'shape': {
						'type': 'circle'
					},
					'opacity': {
						'value': 2,
						'random': true,
						'anim': {
							'enable': true
						}
					},
					'size': {
						'value': 4,
						'random': true,
						'anim': {
							'enable': false,
						}
					},
					'line_linked': {
						'enable': false
					},
					'move': {
						'enable': true,
						'speed': 2,
						'direction': 'none',
						'random': true,
						'straight': false,
						'out_mode': 'out'
					}
				},
				'interactivity': {
					'detect_on': 'canvas',
					'events': {
						'onhover': {
							'enable': false
						},
						'onclick': {
							'enable': false
						},
						'resize': true
					}
				},
				'retina_detect': true
			});
		},
		isInIframe: function () {
			return window.self !== window.top;
		},
	},

	watch: {
		$route() {
			if(this.$route.meta.restrictInIframe && this.isInIframe()) {
				this.$router.push('/widget/sales')
			} 

            if(this.$route.meta.allowInFrame && !this.isInIframe()) {
				this.$router.push('/')
			}
		}
	},

	mounted() { 
		window.$(function () {

			var siteMenuClone = function () {

				window.$('.js-clone-nav').each(function () {
					var $this = window.$(this);
					$this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
				});


				setTimeout(function () {

					var counter = 0;
					window.$('.site-mobile-menu .has-children').each(function () {
						var $this = window.$(this);

						$this.prepend('<span class="fa-solid arrow-collapse collapsed">');

						$this.find('.arrow-collapse').attr({
							'data-toggle': 'collapse',
							'data-target': '#collapseItem' + counter,
						});

						$this.find('> ul').attr({
							'class': 'collapse',
							'id': 'collapseItem' + counter,
						});

						counter++;

					});

				}, 1000);

				window.$('body').on('click', '.arrow-collapse', function (e) {
					var $this = window.$(this);
					if ($this.closest('li').find('.collapse').hasClass('show')) {
						$this.removeClass('active');
					} else {
						$this.addClass('active');
					}
					e.preventDefault();

				});

				window.$(window).resize(function () {
					var $this = window.$(this),
						w = $this.width();

					if (w > 768) {
						if (window.$('body').hasClass('offcanvas-menu')) {
							window.$('body').removeClass('offcanvas-menu');
						}
					}
				})

				window.$('body').on('click', '.js-menu-toggle', function (e) {
					var $this = window.$(this);
					e.preventDefault();

					if (window.$('body').hasClass('offcanvas-menu')) {
						window.$('body').removeClass('offcanvas-menu');
						$this.removeClass('active');
					} else {
						window.$('body').addClass('offcanvas-menu');
						$this.addClass('active');
					}
				})

				// click outisde offcanvas
				window.$(document).mouseup(function (e) {
					var container = window.$(".site-mobile-menu");
					if (!container.is(e.target) && container.has(e.target).length === 0) {
						if (window.$('body').hasClass('offcanvas-menu')) {
							window.$('body').removeClass('offcanvas-menu');
						}
					}
				});
			};
			siteMenuClone();

		});
		this.initParticles()

		if(this.$route.meta.restrictInIframe && this.isInIframe()) {
			this.$router.push('/widget/sales')
		} 

		if(this.$route.meta.allowInFrame && !this.isInIframe()) {
			this.$router.push('/')
		} 
	}
}

</script>