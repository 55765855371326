<template>
    <div class="dashboard-bg">
        <Orientation/>
        <InactivityNotice v-if="this.$store.getters.user && !this.$store.getters.user.details.is_guest"/>
        <Loading v-if="this.$store.getters.isLoading" />
        
        <div class="bg-holder-ap"></div>
        <div class="dashboard-wrap">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Loading from "../components/Loading.vue"
import InactivityNotice from "../components/InactivityNotice.vue"
import Orientation from "../components/OrientationCheck.vue"
export default {
    name: 'CustomView',
    components: {
        Loading,
		Orientation,
        InactivityNotice
    },
 
    data () {
        return {

        }
    },

    computed: {

    },

    methods: {
        isInIframe: function () {
            return window.self !== window.top;
        },
    },

    watch: {
		$route() {
			if(this.$route.meta.restrictInIframe && this.isInIframe()) {
				this.$router.push('/widget/sales')
			} 

            if(this.$route.meta.allowInFrame && !this.isInIframe()) {
				this.$router.push('/')
			} 
		}
	},

    mounted() { 
		if(this.$route.meta.restrictInIframe && this.isInIframe()) {
			this.$router.push('/widget/sales')
		} 

		if(this.$route.meta.allowInFrame && !this.isInIframe()) {
			this.$router.push('/')
		} 
    }
}

</script>